
// Regular
@font-face {
    font-family: 'eurostile-extended';
    src:
        url('/assets/font/EurostileExtended.otf') format("opentype"),
        url('/assets/font/EurostileExtended.woff') format("woff"),
        url('/assets/font/EurostileExtended.woff2') format("woff2"),;
    font-weight: 400;
    font-style: normal;
}

// Bold
@font-face {
    font-family: 'eurostile-extended';
    src: 
        url('/assets/font/EurostileExtendedBlack.otf') format("opentype"),
        url('/assets/font/EurostileExtendedBlack.woff') format("woff"),
        url('/assets/font/EurostileExtendedBlack.woff2') format("woff2"),;
    font-weight: 700;
    font-style: normal;
}

