@use "../../styles/partials/fonts";
@use "../../styles/partials/colors" as *;
@use "../../styles/partials/mixins" as *;

.slider-card {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  transition: all 0.3s ease-in;

  &__image-box {
    position: relative;
  }

  &__image {
    opacity: 1;
    width: 100%;
    object-fit: cover;
    transition: all 0.3s ease-in;
    box-shadow: rgba(100, 100, 111, 0.2) 0rem 0.4375rem 1.8125rem 0rem;
    cursor: pointer;

    &:hover {
      box-shadow: rgba(0, 0, 0, 0.35) 0.125rem 0.3125rem 0.9375rem;
      scale: 1.01;

      .overlay {
        opacity: 1;
      }
    }

    @include tablet {
      width: 100%;
      height: 20vh;
    }

    @include desktop {
      width: 100%;
      height: 25vh;
    }

  }
}

.text {
  background-color: $button-background-primary;
  color: $text-white;
  font-size: 1rem;
  padding: 1rem 2rem;
  transition: all 0.3s ease-in;

  &:hover {
    background-color: $button-background-secondary;
    cursor: pointer;
  }
}

.middle {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.slider-card__image-box:hover .slider-card__image {
  opacity: 0.5;
}

.slider-card__image-box:hover .middle {
  opacity: 1;
}

.slider-animation {
  animation-name: animateIn;
  animation-duration: 400ms;
  animation-fill-mode: both;
  animation-timing-function: ease-in-out;
}

@keyframes animateIn {
  0% {
    opacity: 0;
    transform: scale(0.75) translateX(3rem);
  }

  100% {
    opacity: 1;
  }
}
