@use "../../styles/partials/colors" as *;
@use "../../styles/partials/mixins" as *;

.projects {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 2rem 0;
  z-index: 0;

  @include tablet {
    gap: 2rem;
    height: 100vh;
  }

  @include desktop {
    max-width: 80rem;
  }

  &__heading {
    margin-top: 4rem;

    @include desktop-xl {
      margin-top: 6rem;
    }
  }

  &__container {
    display: grid;
    grid-template-columns: repeat(1, 100%);
    align-self: center;
    width: 100%;
    gap: 1.5rem;
    margin: 2rem 0;

    @include tablet {
      margin: 1rem 0;
      grid-template-columns: repeat(3, 33%);
      grid-template-rows: repeat(2, 50%);
    }
  }
}
