// Main Color Palatte
$background-primary: #FFFFFF;
$background-secondary: #f9f9f9;

// Text
$text-primary: #000000;
$text-secondary: #d08770;
$text-white: #FFFFFF;

// Buttons
$button-background-primary: #000000;
$button-background-secondary: #d08770;


