@use "../../styles/partials/fonts";
@use "../../styles/partials/colors" as *;
@use "../../styles/partials/mixins" as *;

.scroll-to-top-button{
    position: fixed;
    bottom: 2vh;
    right: 3vw;
    text-transform: uppercase;
    background-color: $button-background-primary;
    border: none;
    padding: 0.75rem 1rem;
    max-width: 11.25rem;
    transition: 0.3s ease;
    z-index: 1000;
    cursor: pointer;

    @include tablet{
        font-size: 1rem;
        bottom: 2.5rem;
        right: 2.5rem;
    }

    &__cheveron{
        font-size: 1rem;
        color: $text-white;

        @include tablet{
            font-size: 1.5rem;
        }

        @include desktop{
            font-size: 2rem;
        }
    }

    &:hover{
        @extend .scroll-to-top-button;
        background-color: $button-background-secondary;
    }
}
