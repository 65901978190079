@use './breakpoints' as *;

@mixin tablet{
    @media (min-width: $breakpoint-tablet) {
        @content;
    }
}

@mixin desktop{
    @media (min-width: $breakpoint-desktop) {
        @content;
    }
}

@mixin desktop-xl{
    @media (min-width: $breakpoint-desktop-xl) {
        @content;
    }
}