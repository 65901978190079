@use "../../styles/partials/fonts";
@use "../../styles/partials/colors" as *;
@use "../../styles/partials/mixins" as *;

.project-modal {
  position: fixed; /* Stay in place */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001; /* Sit on top */
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  background-color: $button-background-primary; /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */

  -webkit-animation-name: fadeIn; /* Fade in the background */
  -webkit-animation-duration: 0.4s;
  animation-name: fadeIn;
  animation-duration: 0.4s;

  &__overlay {
    position: fixed; /* Stay in place */
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    z-index: 1002;
  }

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    gap: 1rem;
    height: 85%;
    width: 90%;
    background-color: $background-primary;
    z-index: 1002;
    overflow-y: scroll;

    @include tablet {
      height: 80%;
      width: 80%;
   
    }

    @include desktop {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      width: 80vw;
    }
  }

  &__close-button {
    position: -webkit-sticky;
    position: sticky;
    padding: 1rem;
    width: 2rem;
    height: 2rem;
    top: 0;
    margin-left: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.5s ease;
    color: $text-white;
    background-color: rgb(255, 0, 0);

    cursor: pointer;

    @include desktop {
      height: 3rem;
      width: 3rem;
    }

    &:hover {
      background-color: rgb(200, 0, 0);
    }
  }
}

/* Add Animation */
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
