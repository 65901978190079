@use "../../styles/partials/colors" as *;
@use "../../styles/partials/fonts";
@use "../../styles/partials/mixins" as *;

.contact {
  display: flex;
  flex-direction: column;
  min-height: 1rem;
  max-width: none;
  padding: 0 8%;
  overflow-x: hidden;

  @include desktop {
    min-height: 100vh;

    padding: 0 10%;
    padding-top: 2rem;
  }

  &__main{
   justify-self: center;
   max-width: 1280px;
  }

  &__heading {
    margin-top: 2.5rem;
  }

  &__text {
    width: 100%;
    margin-top: 2rem;
    background-color: $background-primary;

    @include desktop {
      width: 80%;
    }
  }

  &__container {
    display: flex;
    gap: 4rem;
    margin-bottom: 4rem;

  }

  &__form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    margin-top: 2rem;

    @include desktop {
      width: 100%;
    }

    &-top {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;

      @include tablet {
        flex-direction: row;
      }
    }

    &-input {
      font-family: "Rajdhani", sans-serif;
      border: 0.0625rem solid $button-background-primary;
      font-size: 1.25rem;
      width: 100%;
      padding: 1rem;
    }

    &-textarea {
      @extend .contact__form-input;
      height: 10rem;
    }

    &-button {
      display: flex;
      align-self: flex-end;
      width: 33%;
      border: 0.0625rem solid $button-background-primary;
      font-family: eurostile-extended, "Do Hyeon", sans-serif;
      font-size: 1.25rem;
      padding: 0.5rem;
      transition: 0.3s ease;
      background-color: $background-primary;
      color: $text-primary;
      cursor: pointer;

      &:hover {
        background-color: $button-background-primary;
        color: $text-white;
      }
    }
  }
}
