@use "./styles/partials/fonts";
@use "./styles/partials/colors" as *;
@use "./styles/partials/mixins" as *;
@import url("https://fonts.googleapis.com/css2?family=Didact+Gothic&family=Rajdhani:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css?family=Do+Hyeon:400");

*,
::before,
::after {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
p,
a {
  font-family: eurostile-extended, "Do Hyeon", sans-serif;
  color: $text-primary;
  padding: 0;
  margin: 0;
}

h1 {
  font-size: 1.5rem;
  font-weight: 700;

  @include tablet {
    font-size: 2rem;
  }

  @include desktop {
    font-size: 2.25rem;
  }

  @include desktop-xl {
    font-size: 3rem;
  }
}

h2 {
  font-size: 1.25rem;
  font-weight: 700;

  @include tablet {
    font-size: 1.4rem;
  }

  @include desktop {
    font-size: 1.5rem;
  }

  @include desktop-xl {
    font-size: 1.75rem;
  }
}

p {
  font-family: "Rajdhani", sans-serif;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.6;

  @include tablet {
    font-size: 1.125rem;
  }
  @include desktop {
    font-size: 1.25rem;
  }

  @include desktop-xl {
    font-size: 1.5rem;
  }
}

ul,
li {
  margin-left: 1rem;
  padding-left: 0.75rem;
  list-style-type: square;
  list-style-position: outside;
}

a {
  text-decoration: none; /* no underline */
}

html {
  position: relative;
  scroll-behavior: smooth;
  overflow-x: hidden;
  
}

body {
  font-family: "open-sans", sans-serif;
  font-weight: 400;
  margin: 0;
  padding: 0;
}

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $background-primary;
  padding: 2rem 8%;

  @include tablet {
    padding: 2rem 10%;
  }
}

section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  max-width: 80rem;
  scroll-snap-align: start;
}

ul {
  margin-block: 0.65rem;
}

.more-arrow {
  font-size: 2.5rem;
  display: flex;
  justify-content: center;

  &:hover {
    transition: 0.6s ease;
    scale: 1.1;
  }
}

@-webkit-keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-1rem);
  }
}
@keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-1rem);
  }
}

@include desktop {
  .hidden {
    opacity: 0;
  }

  .left-animation {
    opacity: 1;
    animation: fadeInLeft 0.75s ease-in-out;
  }

  .right-animation {
    opacity: 1;
    animation: fadeInRight 0.75s ease-in-out;
  }

  .top-animation {
    opacity: 1;
    animation: fadeInTop 0.75s ease-in-out;
  }

  
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateX(5rem);
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translateX(-5rem);
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeInTop {
  from {
    opacity: 0;
    transform: translateY(-5rem);
  }
  to {
    opacity: 1;
  }
}

.bounce {
  animation: bounce 3s ease infinite;
}

@keyframes bounce {
	0%, 20%, 50%, 80%, 100% {transform: translateY(0);}
	40% {transform: translateY(-1.275rem);}
	60% {transform: translateY(-0.5375rem);}
}
