@use "../../styles/partials/fonts";
@use "../../styles/partials/colors" as *;
@use "../../styles/partials/mixins" as *;

.footer {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: $background-primary;
  opacity: 0.85;
  bottom: 0;

  h3:hover{
    transition: 0.5s ease;
    scale: 1.03;
  }

  &__home {
    font-weight: 700;
    height: 3rem;
    width: 3rem;
    transition: 0.3s ease-in-out;

    &--tm{
      font-size: 0.6rem;
    }

    @include tablet {
      height: 4rem;
      width: 4rem;
    }

    &:hover{
      scale: 1.03;
    }
    
  }

  &__list {
    padding: 0;
    display: flex;
    gap: 2rem;
    font-size: 1rem;
    list-style: none;

    @include tablet {
      font-size: 1.25rem;
    }

    @include desktop {
      font-size: 1.4rem;
      gap: 3rem;
    }

    &-item {
      color: $text-primary;

      &:hover {
        color: $text-secondary;
        transition: 0.3s ease;
      }
    }
  }

  &__privacy{
    cursor: pointer;
    color: $text-primary;

      &:hover {
        color: $text-secondary;
        transition: 0.3s ease;
      }
  }
}

.m-scroll {
  display: flex;
  align-items: center;
  height: 4rem;
  width: 100vw;
  margin-top: 1rem;
  background-color: $button-background-primary;
  overflow: hidden;
  z-index: 1;

  @include tablet{
    height: 5rem;
  }

  @include desktop{
    height: 6rem;
  }

  &__title {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    white-space: nowrap;
    transform: scale(2);
    transition: all 1s ease;
    & > div {
      display: flex;
      animation: scrollText 33s infinite linear;
    }
    h1 {
      margin: 0;
      text-transform: uppercase;
      font-size: 1rem;
      color: rgba(255, 255, 255, 1);
      transition: all 0.5s ease;

      @include tablet {
        font-size: 2.5rem;
      }
    }
    a {
      text-decoration: none;
      color: $text-white;
    }
    a:hover {
      transition: 0.3s ease-in-out;
      -webkit-text-stroke: .0625rem $text-white;
      color: transparent;
    }
  }
}

@keyframes scrollText {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-33%);
  }
}
