@use "../../styles/partials/colors" as *;
@use "../../styles/partials/mixins" as *;

.about-cards {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: .625rem;
  padding: 2rem;
  width: 3.5rem;
  height: 3.5rem;
  background-color: $background-secondary;
  transition: all 0.3s ease-in;

  @include tablet {
    width: 4.5rem;
    height: 4.5rem;
  }

  @include desktop {
    width: 5.5rem;
    height: 5.5rem;
  }

  &__logo {
    width: 3rem;

    @include tablet {
      width: 4rem;
      height: 4rem;
    }
    @include desktop {
      width: 4.5rem;
      height: 4.5rem;
    }
  }

  &__tooltip{
    visibility: hidden;
    width: 7.5rem;
    background-color: $button-background-primary;
    color: #fff;
    text-align: center;
    padding: .3125rem 0;
  
    /* Position the tooltip */
    position: absolute;
    z-index: 1;
  }


  &:hover {
    transition: 0.3s ease;
    scale: 1.03;
    background-color: rgba(74, 74, 74, 0.333);

    & .about-cards__tooltip{
      visibility: visible;
    }
  }
}

.projects-cards {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: .625rem;
  padding: 2rem;
  width: 3rem;
  height: 3em;
  background-color: $background-secondary;
  transition: all 0.3s ease-in;

  @include tablet {
    width: 3.5rem;
    height: 3.5rem;
  }

  @include desktop {
    width: 4rem;
    height: 4rem;
  }

  &__logo {
    width: 2.5rem;
    height: 2.5rem;

    @include desktop {
      width: 3rem;
      height: 3rem;
    }
  }

  &__tooltip{
    visibility: hidden;
    width: 6.875rem;
    background-color: $button-background-primary;
    color: #fff;
    text-align: center;
    padding: .3125rem .125rem;
    /* Position the tooltip */
    position: absolute;
    z-index: 1005;
  }

  &:hover {
    transition: 0.3s ease;
    background-color: rgba(74, 74, 74, 0.333);

    & .projects-cards__tooltip{
      visibility: visible;
    }
  }
}
