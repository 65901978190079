@use "../../styles/partials/colors" as *;
@use "../../styles/partials/mixins" as *;

.projects-card {
  display: flex;
  flex-direction: column;
  padding: 1rem 2rem;
  height: 100%;
  width: 100%;
  gap: 1.5rem;
  transition: 1s ease;

  @include desktop {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1rem;
  }

  &--top{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: 1rem;

    @include desktop{
      width: 50%;
    }
  }

  &__heading{
    margin-bottom: 1rem;
  }

  &__video {
    margin-bottom: 1rem;
    height: 25rem;
    width: 100%;
    object-fit: fit;
    background-color: black;

    @include tablet {
      width: 100%;
      margin-right: 2rem;
    }
  }

  &__image {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
    object-fit: cover;
    width: 100%;

    @include tablet {
      width: 90%;
    }

    @include desktop {
      margin-top: 0;
      margin-bottom: 4rem;
    }
  }

  &__description {
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include desktop{
      width: 50%;
    }
  }

  &__text {
    margin-top: 1rem;
    margin-bottom: 2rem;
  }

  &__tech {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
    margin-bottom: 2rem;
    gap: 1rem;
    flex-wrap: wrap;

    @include tablet {
      justify-content: flex-start;
    }

    @include desktop {
      justify-content: space-around;
      width: 30vw;
    }
  }

  &__button-container {
    display: flex;
    justify-content: center;
    gap: 2rem;
    margin-bottom: 1rem;

    @include tablet {
      justify-content: flex-start;
    }
  }

  &__button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    border: .0625rem solid $button-background-primary;
    font-size: 1.25rem;
    padding: 0.5rem;
    transition: 0.3s ease;

    &:hover {
      background-color: $button-background-primary;
      color: $text-white;
    }
  }
}

.mobile {
  display: flex;

  @include desktop {
    display: none;
  }
}

.desktop {
  display: none;

  @include desktop {
    display: flex;
  }
}
