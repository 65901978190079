@use "../../styles/partials/fonts";
@use "../../styles/partials/colors" as *;
@use "../../styles/partials/mixins" as *;

// This is to prevent hamburger menu from pushing content down in Safari
.navigation-container {
  position: sticky;
  position: -webkit-sticky;
  height: 0;
  top: 0rem;
  padding: 0;
  z-index: 1000;
}

.navigation {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: $background-primary;
  opacity: 0.9;
  backdrop-filter: blur(1.25rem);
  padding: 0.25rem 7%;
  transition: ease-in 1s;

  ul,
  li {
    margin-left: 0rem;
    padding-left: 0rem;
    list-style-type: none;
    list-style-position: outside;
  }

  @include desktop {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0rem 10%;
  }

  &__hamburger {
    padding: 0;
    border: none;
    background: none;
    font-size: 2rem;
    color: $text-primary;
    cursor: pointer;
    transition: ease 1s;

    &:hover {
      scale: 1.03;
    }

    @include desktop {
      display: none;
    }

    &-container {
      display: flex;
      align-items: center;
      gap: 1rem;
    }

    &-list {
      display: flex;
      flex-direction: column;
      gap: 0.75rem;
      animation: fadeInTop 0.5s ease-in-out;

      &-item {
        font-size: 1.15rem;
        font-weight: 500;
        font-family: "Rajdhani", sans-serif;
      }

      @include desktop {
        display: none;
      }
    }
  }

  &__logo {
    width: 1.75rem;
    height: 1.75rem;
    display: none;

    @include desktop {
      display: flex;
    }

    &--text {
      @include desktop {
        display: none;
      }
    }
  }

  &__home {
    display: flex;
    align-items: flex-end;
    gap: 0.5rem;
    font-weight: 700;
    transition: ease-in-out 0.3s;

    @include tablet {
      font-size: 1.15rem;
    }

    @include desktop {
      font-size: 1.25rem;
    }

    @include desktop-xl {
      font-size: 1.4rem;
    }

    &:hover {
      scale: 1.03;
    }
  }

  &__list {
    padding: 0;
    gap: 2rem;
    font-size: 0.8rem;
    display: none;

    @include tablet {
      font-size: 1rem;
      gap: 3rem;
    }

    @include desktop {
      font-size: 1.15rem;
      display: flex;
    }

    @include desktop-xl {
      font-size: 1.25rem;
      gap: 4rem;
    }

    &-item {
      color: $text-primary;
      font-family: "Rajdhani", sans-serif;
      font-weight: 500;

      &:hover {
        color: $text-secondary;
        transition: 0.3s ease;
      }
    }
  }

  @keyframes fadeInTop {
    from {
      opacity: 0;
      transform: translateY(-9.75rem);
    }
    to {
      opacity: 1;
    }
  }
}
