@use "../../styles/partials/colors" as *;
@use "../../styles/partials/mixins" as *;

.experience-card {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
  min-height: 1rem;
  gap: 6rem;
  transition: 1s ease;

  @include desktop {
    flex-direction: row;
    align-items: center;
  }

  &__info {
    width: 100%;
    @include desktop {
      width: 60%;
    }
  }

  &__title {
    font-weight: 700;
    font-size: 1.5rem;

    @include tablet{
      font-size: 1.75rem;
    }
  }

  &__company {
    font-family: "Rajdhani", sans-serif;
    font-weight: 500;
    margin-top: 0.5rem;
    margin-bottom: 0.75rem;
    font-size: 1.4rem;

    @include tablet{
      font-size: 1.65rem;
    }
  }

  &__right{
    display: flex;
    justify-content: center;
    width: 80%;

    @include desktop {
      margin-top: 0;
      width: 35%;
    }
  }

  &__image {
    margin-top: 5vh;
    width: 100%;
    transition: 0.5s ease;
    max-width: 28rem;
  
    @include desktop {
      margin-top: 0;
      max-width: 28rem;
    }

    &:hover {
      scale: 1.03;
      @include tablet {
        box-shadow: -1rem -1rem #ccc;
      }

      @include desktop {
        box-shadow: -2rem -2rem #ccc;
      }
    }
  }

  &__list{
    margin: 0;
    padding: 0;
    margin-top: 1rem;
  }
}
