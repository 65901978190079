@use "../../styles/partials/colors" as *;
@use "../../styles/partials/mixins" as *;

.experience {
  position: relative;
  display: flex;
  gap: 10vh;
  flex-direction: column;
  z-index: 0;

  @include desktop {
    gap: 6rem;
  }

  &__heading {
    margin-top: 4.5rem;

    @include desktop-xl {
      margin-top: 6rem;
    }
  }
}
