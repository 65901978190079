@use "../../styles/partials/colors" as *;
@use "../../styles/partials/mixins" as *;

.about {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 0;

  &__heading {
    margin-top: 4.5rem;
    margin-bottom: 2rem;
    align-self: flex-start;

    @include desktop {
      margin-top: 5rem;
    }

    @include desktop-xl {
      margin-top: 5rem;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin-bottom: 2.5rem;

    @include tablet {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 0;
    }
  }

  &__profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2rem;
    gap: 2rem;
    width: 100%;

    @include tablet {
      width: 45%;
    }

    &-image {
      width: 55%;
      border-radius: 50%;
      @include desktop {
        width: 45%;
      }
    }
  }

  &__cards-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 2rem;

    @include tablet {
      width: 45%;
    }
  }

  &__cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    gap: 2rem;
  }
}
